import styled from '@emotion/styled';
import {
    color_black,
    color_white,
    color_lightest_grey,
    color_dark_grey,
    color_eggshell
} from '@src/styles/colors';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { Splide } from '@splidejs/react-splide';
import { SplideSlide } from '@splidejs/react-splide';

export const SectionContainer = styled('div')`
    background-color: ${color_eggshell.base};
    padding: ${getStyleSizes([78, 0, 0])};
    overflow: hidden;
    ${mq_min('md')} {
        padding: ${getStyleSizes([98, 0, 0])};
    }
    ${mq_min('lg')} {
        padding: ${getStyleSizes([97, 0, 0])};
    }
`;

export const HeadlineStyled = styled('div')`
    color: ${color_black};
    text-align: center;
    font-size:  ${getStyleSizes(40)};
    font-style: normal;
    font-weight: 500;
    line-height: ${getStyleSizes(48)};
    margin-bottom: ${getStyleSizes(49)};
    ${mq_min('md')} {
      font-size:  ${getStyleSizes(48)};
      line-height: ${getStyleSizes(60)};
      margin-bottom: ${getStyleSizes(81)};
    }
    ${mq_min('lg')} {
      margin-bottom: 0;
    }
`

export const TestimonialSplideStyled = styled(Splide)`
  position: relative;
  .splide__arrow--prev,
  .splide__arrow--next{
    opacity: 1;
    outline: 0;
    top: 25%;
    ${mq_min('md')} {
      top: 10.5%;
    }
    ${mq_min('lg')} {
      top: 33.5%;
    }
    background-repeat: no-repeat;
    background-color: transparent;
    width: ${getStyleSizes(16)};
    height: ${getStyleSizes(16)};
    svg{
      display: none;
    }
  }
  .splide__arrow--prev{
    left: 136px;
    ${mq_min('md')} {
      left: 160px;
    }
    ${mq_min('lg')} {
      left: 190px;
    }
    transform: translateX(-113px) scale(1.5);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M4.648 9.31047C4.7012 9.35 4.75693 9.38571 4.81467 9.41739L11.0144 13.3851L11.0373 13.4017L11.0776 13.4256C11.192 13.4879 11.3218 13.5119 11.4495 13.4945C11.5773 13.4771 11.6969 13.4191 11.7924 13.3282C11.8879 13.2374 11.9546 13.1181 11.9837 12.9863C12.0127 12.8545 12.0027 12.7165 11.955 12.5908L10.5986 8.0002L11.955 3.41147C12.0042 3.28187 12.0133 3.13927 11.9811 3.00387C11.9488 2.86854 11.8769 2.7472 11.7754 2.65714C11.6739 2.56707 11.5481 2.5128 11.4156 2.502C11.2831 2.4912 11.1508 2.5244 11.0373 2.59693L10.7986 2.74253L9.9405 3.29534L7.96633 4.55407L4.80767 6.5812C4.7534 6.61274 4.70067 6.6472 4.64973 6.6844C4.4492 6.83087 4.28533 7.02614 4.1722 7.25347C4.05907 7.4808 4 7.73353 4 7.99007C4 8.2466 4.05907 8.49934 4.1722 8.72667C4.28533 8.95407 4.4492 9.14927 4.64973 9.29573L4.648 9.31047Z' fill='%2325C9EF'/%3E%3C/svg%3E");
    &:hover{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M4.648 9.31047C4.7012 9.35 4.75693 9.38571 4.81467 9.41739L11.0144 13.3851L11.0373 13.4017L11.0776 13.4256C11.192 13.4879 11.3218 13.5119 11.4495 13.4945C11.5773 13.4771 11.6969 13.4191 11.7924 13.3282C11.8879 13.2374 11.9546 13.1181 11.9837 12.9863C12.0127 12.8545 12.0027 12.7165 11.955 12.5908L10.5986 8.0002L11.955 3.41147C12.0042 3.28187 12.0133 3.13927 11.9811 3.00387C11.9488 2.86854 11.8769 2.7472 11.7754 2.65714C11.6739 2.56707 11.5481 2.5128 11.4156 2.502C11.2831 2.4912 11.1508 2.5244 11.0373 2.59693L10.7986 2.74253L9.9405 3.29534L7.96633 4.55407L4.80767 6.5812C4.7534 6.61274 4.70067 6.6472 4.64973 6.6844C4.4492 6.83087 4.28533 7.02614 4.1722 7.25347C4.05907 7.4808 4 7.73353 4 7.99007C4 8.2466 4.05907 8.49934 4.1722 8.72667C4.28533 8.95407 4.4492 9.14927 4.64973 9.29573L4.648 9.31047Z' fill='%233ACDEF'/%3E%3C/svg%3E");
    }
    &:disabled,
    &:active{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M4.648 9.31047C4.7012 9.35 4.75693 9.38571 4.81467 9.41739L11.0144 13.3851L11.0373 13.4017L11.0776 13.4256C11.192 13.4879 11.3218 13.5119 11.4495 13.4945C11.5773 13.4771 11.6969 13.4191 11.7924 13.3282C11.8879 13.2374 11.9546 13.1181 11.9837 12.9863C12.0127 12.8545 12.0027 12.7165 11.955 12.5908L10.5986 8.0002L11.955 3.41147C12.0042 3.28187 12.0133 3.13927 11.9811 3.00387C11.9488 2.86854 11.8769 2.7472 11.7754 2.65714C11.6739 2.56707 11.5481 2.5128 11.4156 2.502C11.2831 2.4912 11.1508 2.5244 11.0373 2.59693L10.7986 2.74253L9.9405 3.29534L7.96633 4.55407L4.80767 6.5812C4.7534 6.61274 4.70067 6.6472 4.64973 6.6844C4.4492 6.83087 4.28533 7.02614 4.1722 7.25347C4.05907 7.4808 4 7.73353 4 7.99007C4 8.2466 4.05907 8.49934 4.1722 8.72667C4.28533 8.95407 4.4492 9.14927 4.64973 9.29573L4.648 9.31047Z' fill='%23444D5E'/%3E%3C/svg%3E");
    }
  }
  .splide__arrow--next{
    right: 136px;
     ${mq_min('md')} {
      right: 160px;
    }
    ${mq_min('lg')} {
      right: 190px;
    }
    transform: translateX(113px) scale(1.5);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M11.352 6.68953C11.2988 6.65 11.2431 6.61429 11.1853 6.58261L4.98555 2.61491L4.96274 2.59832L4.92238 2.57436C4.80799 2.51213 4.67822 2.4881 4.55047 2.50552C4.42271 2.52292 4.30308 2.58094 4.20761 2.67177C4.11213 2.76261 4.04539 2.88193 4.01632 3.01373C3.98725 3.14554 3.99725 3.28352 4.04497 3.40918L5.40145 7.9998L4.04497 12.5885C3.9958 12.7181 3.98669 12.8607 4.01893 12.9961C4.05115 13.1315 4.1231 13.2528 4.22458 13.3429C4.32606 13.4329 4.45194 13.4872 4.58441 13.498C4.71687 13.5088 4.84923 13.4756 4.96274 13.4031L5.20139 13.2575L6.0595 12.7047L8.03367 11.4459L11.1923 9.4188C11.2466 9.38726 11.2993 9.3528 11.3503 9.3156C11.5508 9.16913 11.7147 8.97386 11.8278 8.74653C11.9409 8.5192 12 8.26647 12 8.00993C12 7.7534 11.9409 7.50066 11.8278 7.27333C11.7147 7.04593 11.5508 6.85073 11.3503 6.70427L11.352 6.68953Z' fill='%2325C9EF'/%3E%3C/svg%3E");
    &:hover{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M11.352 6.68953C11.2988 6.65 11.2431 6.61429 11.1853 6.58261L4.98555 2.61491L4.96274 2.59832L4.92238 2.57436C4.80799 2.51213 4.67822 2.4881 4.55047 2.50552C4.42271 2.52292 4.30308 2.58094 4.20761 2.67177C4.11213 2.76261 4.04539 2.88193 4.01632 3.01373C3.98725 3.14554 3.99725 3.28352 4.04497 3.40918L5.40145 7.9998L4.04497 12.5885C3.9958 12.7181 3.98669 12.8607 4.01893 12.9961C4.05115 13.1315 4.1231 13.2528 4.22458 13.3429C4.32606 13.4329 4.45194 13.4872 4.58441 13.498C4.71687 13.5088 4.84923 13.4756 4.96274 13.4031L5.20139 13.2575L6.0595 12.7047L8.03367 11.4459L11.1923 9.4188C11.2466 9.38726 11.2993 9.3528 11.3503 9.3156C11.5508 9.16913 11.7147 8.97386 11.8278 8.74653C11.9409 8.5192 12 8.26647 12 8.00993C12 7.7534 11.9409 7.50066 11.8278 7.27333C11.7147 7.04593 11.5508 6.85073 11.3503 6.70427L11.352 6.68953Z' fill='%233ACDEF'/%3E%3C/svg%3E");
    }
    &:disabled,
    &:active{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M11.352 6.68953C11.2988 6.65 11.2431 6.61429 11.1853 6.58261L4.98555 2.61491L4.96274 2.59832L4.92238 2.57436C4.80799 2.51213 4.67822 2.4881 4.55047 2.50552C4.42271 2.52292 4.30308 2.58094 4.20761 2.67177C4.11213 2.76261 4.04539 2.88193 4.01632 3.01373C3.98725 3.14554 3.99725 3.28352 4.04497 3.40918L5.40145 7.9998L4.04497 12.5885C3.9958 12.7181 3.98669 12.8607 4.01893 12.9961C4.05115 13.1315 4.1231 13.2528 4.22458 13.3429C4.32606 13.4329 4.45194 13.4872 4.58441 13.498C4.71687 13.5088 4.84923 13.4756 4.96274 13.4031L5.20139 13.2575L6.0595 12.7047L8.03367 11.4459L11.1923 9.4188C11.2466 9.38726 11.2993 9.3528 11.3503 9.3156C11.5508 9.16913 11.7147 8.97386 11.8278 8.74653C11.9409 8.5192 12 8.26647 12 8.00993C12 7.7534 11.9409 7.50066 11.8278 7.27333C11.7147 7.04593 11.5508 6.85073 11.3503 6.70427L11.352 6.68953Z' fill='%23444D5E'/%3E%3C/svg%3E");
    }
  }

  .splide__pagination{
    display: none;
  }
`;

export const SplideSlideStyled = styled(SplideSlide)`
  background-image: url('https://res.cloudinary.com/spiralyze/image/upload/f_auto/assembly/12001/circles_4.webp');
  background-size: 176px;
  background-position: left bottom;
  background-repeat: no-repeat;
  ${mq_min('md')} {
    background-size: 171px;
    background-position: left -50px bottom -47px;
  }
  ${mq_min('lg')} {
    background-image: url('https://res.cloudinary.com/spiralyze/image/upload/f_auto/assembly/12001/circles_11.webp');
    background-size: 220px;
    background-position: left 1px bottom 5px
  }
`

export const SplideContainer = styled('div')`
  display: flex;
  justify-content: center;
  max-width:  ${getStyleSizes(1240)};
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${mq_min('lg')} {
    flex-direction: row;
  }
`;

export const TestimonialCopyStyled = styled('div')`
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  padding-left: ${getStyleSizes(54)};
  padding-right: ${getStyleSizes(56)};
  ${mq_min('md')} {
    padding-left: ${getStyleSizes(100)};
    padding-right: ${getStyleSizes(100)};
  }
  ${mq_min('lg')} {
    width: 30%;
    margin-right: -170px;
    margin-left: 149px;
    margin-top: 81px;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const NameStyled = styled('div')`
  color: ${color_black};
  font-size: ${getStyleSizes(28)};
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  ${mq_min('md')} {
    font-size: ${getStyleSizes(32)};
  }
`;

export const DesignationStyled = styled('div')`
  color: ${color_black};
  font-size: ${getStyleSizes(16)};
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: ${getStyleSizes(48)};
  ${mq_min('md')} {
    margin-bottom: ${getStyleSizes(24)};
  }
`;

export const ContentStyled = styled('div')`
  color: ${color_dark_grey};
  font-size:  ${getStyleSizes(18)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const TestimonialImageStyled = styled('div')`
  width: 100%;
  margin-top: 33px;
  position: relative;
  display: flex;
  overflow: hidden;
  height: 372px;
  ${mq_min('md')} {
    margin-top: 6px;
    height: 496px;
  }
  ${mq_min('lg')} {
    width: 70%;
    overflow: visible;
  }
  img{
    height: 100%;
    object-fit: cover;
    position: relative;
    object-position: center;
    left: calc(50% - 60px);
    transform: translateX(-50%);
    ${mq_min('md')} {
      left: calc(50% - 129px);
    }
    ${mq_min('lg')} {
      left: -85px;
      transform: none;
    }
  }
`;
